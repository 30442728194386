body {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: 'Ubuntu';
  src: local('Ubuntu'), url(./fonts/Ubuntu-Bold.ttf) format('truetype')
}

@font-face {
  font-family: 'Normal';
  src: local('Ubuntu'), url(./fonts/Ubuntu-Regular.ttf) format('truetype')
}
