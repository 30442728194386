a.nav_link:hover {
  cursor: pointer;
  color: #2e5984;
}

.nav_link {
  text-decoration: none;
  color: black;
}

.menu_link {
  text-decoration: none;
  color: white;
  font-size: 20px;
  margin-left: 35px;
  text-align: left;
}

a.menu_link:hover {
  cursor: pointer;
  color: gold;
}
